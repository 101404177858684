<template>
  <div class="view-home">
    <div class="one-forum-title-block">
      <div class="container" style="position: relative; z-index: 1">
        <nav class="breadcrumbs-block">
          <ul class="breadcrumbs-list">
            <li class="breadcrumbs-item">
              <router-link to="/">{{ $t('navbar.home') }}</router-link>
            </li>
            <li class="breadcrumbs-item">
              <router-link to="/events">{{ $t('events-main') }}</router-link>
            </li>
            <li class="breadcrumbs-item">{{ $t('forums') }}</li>
          </ul>
        </nav>
        <div class="one-forum-title">
          {{ $t('forums') }}
        </div>
        <div class="one-forum-subtitle">
          <router-link to="/events">
            <svg width="65" height="66" viewBox="0 0 65 66" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M32.5 57.375C45.9619 57.375 56.875 46.4619 56.875 33C56.875 19.5381 45.9619 8.625 32.5 8.625C19.0381 8.625 8.125 19.5381 8.125 33C8.125 46.4619 19.0381 57.375 32.5 57.375Z"
                  stroke="white" stroke-width="3" stroke-miterlimit="10"/>
              <path d="M36.5625 23.8594L26.4062 33L36.5625 42.1406" stroke="white" stroke-width="3"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </router-link>
          {{ forumInfo[`name_${$i18n.locale}`] || forumInfo.name_ru }}
        </div>
      </div>
    </div>

    <div class="one-forum-back">
      <div class="container">
        <div class="one-forum-details-block">
          <div class="one-forum-details-left">
            <div class="line-top"></div>
            <div class="one-forum-section-title">
              {{ $t('purposeForum') }}
            </div>
            <div class="one-forum-section-text">
              {{ forumInfo[`goal_${$i18n.locale}`] || forumInfo.goal_ru }}
            </div>
            <div class="line-top"></div>
            <div class="one-forum-section-title">
              {{ $t('tasksForum') }}
            </div>
            <div class="one-forum-section-text">
              {{ forumInfo[`tasks_${$i18n.locale}`] || forumInfo.tasks_ru }}
            </div>
          </div>
          <div class="one-forum-details-right">
            <div class="one-forum-results">
              <div class="one-forum-results-title">
                {{ $t('resultsForum') }}
              </div>
              <div class="one-forum-results-text">
                {{ forumInfo[`result_${$i18n.locale}`] || forumInfo.result_ru }}
              </div>
              <div class="one-forum-results-details">
                <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="61" height="61" rx="13.9377" fill="#F1E8FF"/>
                  <path
                      d="M36.7814 16.3684V22.6491M24.2201 16.3684V22.6491M17.9395 28.9297H43.062M17.9395 22.6491C17.9395 21.8162 18.2703 21.0174 18.8592 20.4285C19.4482 19.8396 20.2469 19.5087 21.0798 19.5087H39.9217C40.7546 19.5087 41.5533 19.8396 42.1423 20.4285C42.7312 21.0174 43.062 21.8162 43.062 22.6491V41.491C43.062 42.3239 42.7312 43.1226 42.1423 43.7115C41.5533 44.3005 40.7546 44.6313 39.9217 44.6313H21.0798C20.2469 44.6313 19.4482 44.3005 18.8592 43.7115C18.2703 43.1226 17.9395 42.3239 17.9395 41.491V22.6491ZM24.2201 35.2104H27.3604V38.3507H24.2201V35.2104Z"
                      stroke="#6E00FA" stroke-width="2.28893" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                {{ $t('datesOfEvent') }}: {{ formattedDate(forumInfo.date_from, forumInfo.date_to) }}
              </div>
              <div class="one-forum-results-details">
                <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="61" height="61" rx="13.833" fill="#F1E8FF"/>
                  <path
                      d="M25.5785 28.8593C25.5785 30.1647 26.0971 31.4166 27.0202 32.3397C27.9432 33.2628 29.1952 33.7814 30.5006 33.7814C31.806 33.7814 33.058 33.2628 33.9811 32.3397C34.9041 31.4166 35.4227 30.1647 35.4227 28.8593C35.4227 27.5538 34.9041 26.3019 33.9811 25.3788C33.058 24.4557 31.806 23.9372 30.5006 23.9372C29.1952 23.9372 27.9432 24.4557 27.0202 25.3788C26.0971 26.3019 25.5785 27.5538 25.5785 28.8593Z"
                      stroke="#6E00FA" stroke-width="2.27174" stroke-linecap="round" stroke-linejoin="round"/>
                  <path
                      d="M39.7821 38.1407L32.8206 45.1022C32.2053 45.7169 31.3711 46.0621 30.5014 46.0621C29.6317 46.0621 28.7976 45.7169 28.1823 45.1022L21.2192 38.1407C19.3836 36.305 18.1336 33.9663 17.6272 31.4202C17.1208 28.8741 17.3807 26.235 18.3742 23.8366C19.3677 21.4383 21.05 19.3884 23.2085 17.9461C25.367 16.5039 27.9046 15.7341 30.5006 15.7341C33.0966 15.7341 35.6343 16.5039 37.7927 17.9461C39.9512 19.3884 41.6336 21.4383 42.627 23.8366C43.6205 26.235 43.8805 28.8741 43.3741 31.4202C42.8677 33.9663 41.6176 36.305 39.7821 38.1407Z"
                      stroke="#6E00FA" stroke-width="2.27174" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                {{ $t('venue') }}: {{ forumInfo[`venue_${$i18n.locale}`] || forumInfo.venue_ru }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="one-forum-speakers-block">

        <div class="line-top"></div>
        <div class="one-forum-section-title">
          {{ $t('speakersForum') }}
        </div>
        <div class="one-forum-speakers-slider">
          <carousel :perPage="perPageSpeakers" :loop="true" :autoplay="false" :scroll-per-page="false"
                    :autoplayTimeout="5000"
                    :navigationEnabled="true"
                    :paginationEnabled="false" class="one-forum-speakers-carousel">
            <slide class="one-forum-slide-block" v-for="slide in forumInfo.speakers">
              <div class="one-forum-slide one-forum-slide-shadow">
                <div class="one-forum-slide-details">
                  <img :src="slide.avatar" width="80" height="80" alt=""/>
                  <div>
                    <div class="one-forum-slide-name">
                      {{ slide[`name_${$i18n.locale}`] }}
                    </div>
                    <div class="one-forum-slide-job">
                      {{ slide[`position_${$i18n.locale}`] }}
                    </div>
                  </div>
                </div>
                <!--                <div class="one-forum-slide-text">-->
                <!--                  {{slide[`position_${$i18n.locale}`] || slide.position_ru}}-->
                <!--                </div>-->
                <div class="one-forum-slide-text">
                  {{ slide[`topic_${$i18n.locale}`] || slide.topic_ru }}
                </div>
              </div>
            </slide>

          </carousel>
        </div>
      </div>
    </div>
    <div class="one-forum-program-back">
      <div class="container">
        <div class="one-forum-program-block">
          <div class="line-top"></div>
          <div class="one-forum-section-title">
            {{ $t('programForum') }}
          </div>

          <div class="one-forum-btns">
            <a class="one-forum-program-link" :href="forumInfo.program">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M11.0234 17.6187C10.8148 17.6187 10.6062 17.5391 10.4492 17.382L6.79766 13.7281C6.48359 13.4117 6.48359 12.8984 6.8 12.582C7.11406 12.2656 7.62734 12.2656 7.94375 12.582L10.2148 14.8531V1.31094C10.2148 0.863281 10.5781 0.5 11.0258 0.5C11.4734 0.5 11.8367 0.863281 11.8367 1.31094V14.8531L14.1078 12.582C14.2602 12.4297 14.4641 12.3453 14.6797 12.3453C14.8953 12.3453 15.0992 12.4297 15.2516 12.582C15.568 12.8984 15.568 13.4117 15.2516 13.7281L11.5977 17.382C11.4383 17.5391 11.232 17.6187 11.0234 17.6187ZM19.8781 14.8203C19.8781 14.3727 20.2414 14.0094 20.6891 14.0094C21.1367 14.0094 21.5 14.3727 21.5 14.818V20.0422C21.5 20.8438 20.8484 21.4977 20.0445 21.4977H1.95547C1.15391 21.4977 0.5 20.8461 0.5 20.0422V14.818C0.5 14.3727 0.860938 14.007 1.30859 14.007C1.75625 14.007 2.11953 14.3703 2.11953 14.818V19.4C2.11953 19.6672 2.3375 19.8828 2.60234 19.8828H19.3953C19.6625 19.8828 19.8781 19.6648 19.8781 19.4V14.8203Z"
                      fill="#1090CB"/>
              </svg>
              Программа Форума.pdf*
            </a>
            <button class="one-forum-signup" @click="modalSignupForum=true" v-if="isBeforeForumDate">
              <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.0273 21.8879C9.69141 25.8606 4.39453 25.8606 4.39453 25.8606C4.39453 25.8606 4.39453 20.5637 8.36719 19.2278"
                    stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M22.957 12.6061L15 20.5631L9.69141 15.2545L17.6484 7.2975C20.6602 4.28578 23.6719 4.32094 24.9609 4.50844C25.1598 4.53497 25.3445 4.62623 25.4864 4.76813C25.6283 4.91003 25.7196 5.09468 25.7461 5.29359C25.9336 6.58266 25.9687 9.59438 22.957 12.6061Z"
                    stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M21.6328 13.9309V21.5012C21.6293 21.7485 21.5282 21.9844 21.3516 22.1575L17.5664 25.9543C17.4463 26.0743 17.2959 26.1594 17.1313 26.2006C16.9666 26.2417 16.7938 26.2374 16.6314 26.1881C16.469 26.1388 16.3231 26.0463 16.2091 25.9205C16.0951 25.7947 16.0175 25.6404 15.9844 25.4739L15 20.5637"
                    stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                <path
                    d="M16.3245 8.62231H8.75419C8.5069 8.62584 8.27103 8.72693 8.09794 8.90356L4.30106 12.6887C4.18111 12.8088 4.09602 12.9592 4.05485 13.1239C4.01368 13.2885 4.01799 13.4613 4.06731 13.6237C4.11664 13.7861 4.20912 13.9321 4.33491 14.046C4.46069 14.16 4.61506 14.2377 4.78153 14.2708L9.69169 15.2551"
                    stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ $t('programForum1') }}
            </button>
          </div>

          <div class="one-forum-program-days">
            <div class="one-forum-speakers-slider">
              <carousel :perPage="perPageDays" :loop="true" :autoplay="false" :scroll-per-page="false"
                        :autoplayTimeout="5000"
                        :navigationEnabled="true"
                        :paginationEnabled="false" class="one-forum-speakers-carousel">
                <slide class="one-forum-slide-block" v-for="(item, index) in daysProgram" :key="index">
                  <div class="one-forum-slide-wrapper">
                    <div class="one-forum-slide">
                      <div class="one-forum-slide-title">
                        День {{ item[`day_${$i18n.locale}`] }}
                      </div>
                      <div class="one-forum-slide-subtitle">

                      </div>

                      <div class="one-forum-slide-day-section">
                        <div class="one-forum-slide-day-wrapper" v-for="(slide, number) in item.data">
                          <div class="one-forum-slide-desc">
                            <div class="one-forum-slide-desc-text" style="color: #1090CB;">
                              {{ formatTime(slide.time) }}
                            </div>
                            <div class="one-forum-slide-desc-text"
                                 v-html="slide[`text_${$i18n.locale}`] || slide[`description_${$i18n.locale}`]"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </slide>


              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Выбор записи на форум -->
    <el-dialog
        :visible.sync="modalSignupForum"
        :width="modalSignupForumWidth"
    >
      <div class="forum-signup-header">
        <img width="48" height="48" :src="forumInfo.banner" alt=""/>
        <div class="forum-signup-desc">
          <div class="forum-signup-title">
            {{ $t('modalRegForumTitle') }}
          </div>
          <div class="forum-signup-text">
            {{forumInfo.name}}
          </div>
        </div>
      </div>
      <div class="forum-price-title" v-if="!this.forumInfo.is_paid">
        {{ $t('modalRegForum1') }}
      </div>
      <div class="forum-role">
        {{ $t('modalRegForum2') }}
      </div>
      <div class="forum-role-list">
        <div class="forum-role-item" :class="{'active-role' : modalCurrentRole == 'withReport'}" @click="modalCurrentRole='withReport'">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.81203 17.0833H17.9862C19.7279 17.0833 20.6445 16.1667 20.6445 14.425V3.33331H4.14453V14.425C4.1537 16.1667 5.07037 17.0833 6.81203 17.0833Z" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M3.23828 3.33331H21.5716" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.73828 21.6666L12.4049 19.8333V17.0833" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.071 21.6666L12.4043 19.8333" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.2793 11.5833L11.1668 9.17248C11.396 8.97998 11.6985 9.03498 11.8543 9.29165L12.9543 11.125C13.1101 11.3816 13.4126 11.4275 13.6418 11.2442L16.5293 8.83331" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="forum-role-desc">
            <div class="forum-role-title">
              <div class="forum-role-price" v-if="this.forumInfo.is_paid">{{parseInt(this.forumInfo.price_with_speech_and_publication)}} KZT</div>
              {{ $t('modalRegForum3') }}
            </div>
            <div class="forum-role-desc">
              {{ $t('modalRegForum4') }}
            </div>
          </div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="no-check">
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white"/>
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#D0D5DD"/>
          </svg>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="yes-check">
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#1090CB"/>
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#1090CB"/>
            <path d="M11.3327 5.5L6.74935 10.0833L4.66602 8" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="forum-role-item" :class="{'active-role' : modalCurrentRole == 'noReport'}" @click="modalCurrentRole='noReport'">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.8439 7.875C18.7065 9.78141 17.2923 11.25 15.7501 11.25C14.2079 11.25 12.7914 9.78188 12.6564 7.875C12.5157 5.89172 13.8925 4.5 15.7501 4.5C17.6078 4.5 18.9845 5.92781 18.8439 7.875Z" stroke="#242424" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.7498 14.25C12.695 14.25 9.75731 15.7673 9.02138 18.7223C8.92388 19.1133 9.16903 19.5 9.57075 19.5H21.9293C22.3311 19.5 22.5748 19.1133 22.4787 18.7223C21.7428 15.72 18.8051 14.25 15.7498 14.25Z" stroke="#242424" stroke-miterlimit="10"/>
            <path d="M9.37495 8.71594C9.26527 10.2384 8.12245 11.4375 6.89058 11.4375C5.65871 11.4375 4.51402 10.2389 4.40621 8.71594C4.29418 7.13203 5.40652 6 6.89058 6C8.37464 6 9.48698 7.16109 9.37495 8.71594Z" stroke="#242424" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.65671 14.344C8.81061 13.9563 7.87874 13.8073 6.89108 13.8073C4.45358 13.8073 2.10515 15.019 1.51686 17.3791C1.43952 17.6913 1.63546 18.0002 1.95608 18.0002H7.21921" stroke="#242424" stroke-miterlimit="10" stroke-linecap="round"/>
          </svg>
          <div class="forum-role-desc">
            <div class="forum-role-title">
              <div class="forum-role-price" v-if="this.forumInfo.is_paid">{{parseInt(this.forumInfo.price_without_speech_and_publication)}} KZT</div>
              {{ $t('modalRegForum5') }}
            </div>
            <div class="forum-role-desc">
              {{ $t('modalRegForum6') }}
            </div>
          </div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="no-check">
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white"/>
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#D0D5DD"/>
          </svg>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="yes-check">
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#1090CB"/>
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#1090CB"/>
            <path d="M11.3327 5.5L6.74935 10.0833L4.66602 8" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="forum-role-item" :class="{'active-role' : modalCurrentRole == 'onlyPublication'}" @click="modalCurrentRole='onlyPublication'">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 15.75V2.25L9 3L10.5 2.25L11.9972 3L13.5145 2.25L15 3L16.4902 2.25L17.9869 3L19.5 2.25L21.0005 3L22.5 2.25V12.75" stroke="#242424" stroke-linejoin="round"/>
            <path d="M22.5 12.75V18C22.5 18.9946 22.1049 19.9484 21.4017 20.6517C20.6984 21.3549 19.7446 21.75 18.75 21.75V21.75C17.7555 21.75 16.8016 21.3549 16.0984 20.6517C15.3951 19.9484 15 18.9946 15 18V15.75H2.25003C2.15129 15.7491 2.05337 15.7679 1.96198 15.8053C1.87059 15.8427 1.78757 15.8979 1.71775 15.9677C1.64793 16.0375 1.59272 16.1206 1.55534 16.212C1.51796 16.3033 1.49915 16.4013 1.50003 16.5C1.50003 19.5 1.81597 21.75 5.25003 21.75H18.75" stroke="#242424" stroke-linejoin="round"/>
            <path d="M10.5 6.75H19.5" stroke="#242424" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.5 10.5H19.5" stroke="#242424" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="forum-role-desc">
            <div class="forum-role-title">
              <div class="forum-role-price" v-if="this.forumInfo.is_paid">{{parseInt(this.forumInfo.price_only_publication)}} KZT</div>
              {{ $t('modalRegForum7') }}
            </div>
            <div class="forum-role-desc">
              {{ $t('modalRegForum8') }}
            </div>
          </div>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="no-check">
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white"/>
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#D0D5DD"/>
          </svg>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="yes-check">
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#1090CB"/>
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#1090CB"/>
            <path d="M11.3327 5.5L6.74935 10.0833L4.66602 8" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="forums-btns">
          <button class="forums-btn">
            {{ $t('profile.notes.cancel-btn-2') }}
          </button>
          <button class="forums-btn choose-btn" @click="nextModal">
            {{ $t('profile.notes.btn-select') }}
          </button>
        </div>
      </div>
    </el-dialog>

    <!-- Форма записи на форум (Выступление с докладом и публикацией) -->
    <el-dialog
        :visible.sync="modalSignupForumReport"
        :width="modalSignupForumWidth"
    >
      <div class="forum-signup-header">
        <img width="48" height="48" :src="forumInfo.banner" alt=""/>
        <div class="forum-signup-desc">
          <div class="forum-signup-title">
            {{ $t('modalRegForumTitle') }}
          </div>
          <div class="forum-signup-text">
            {{forumInfo.name}}
          </div>
        </div>
      </div>
      <div class="forum-price-title" v-if="!this.forumInfo.is_paid">
        {{ $t('modalRegForum1') }}
      </div>
      <div class="forum-role-block">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.81203 17.0833H17.9862C19.7279 17.0833 20.6445 16.1667 20.6445 14.425V3.33331H4.14453V14.425C4.1537 16.1667 5.07037 17.0833 6.81203 17.0833Z" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3.23828 3.33331H21.5716" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.73828 21.6666L12.4049 19.8333V17.0833" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.071 21.6666L12.4043 19.8333" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.2793 11.5833L11.1668 9.17248C11.396 8.97998 11.6985 9.03498 11.8543 9.29165L12.9543 11.125C13.1101 11.3816 13.4126 11.4275 13.6418 11.2442L16.5293 8.83331" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="forum-role-desc">
          <div class="forum-role-title">
            {{ $t('modalRegForum3') }}
          </div>
          <div class="forum-role-desc">
            {{ $t('modalRegForum4') }}
          </div>
        </div>
      </div>
      <div class="forum-role">
        {{ $t('profile.consultations.modal-title') }}
      </div>
      <div class="forum-plan">
<!--        на участие во II Международном форуме психологов-->
<!--        (выступление с докладом и публикация)-->
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum9') }}
        </div>
        <el-input placeholder="Одра Такер " v-model="presentationForm.name"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum10') }}
        </div>
        <el-input placeholder="Доктор психологических наук" v-model="presentationForm.position"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum11') }}
        </div>
        <el-input
            type="textarea"
            :rows="4"
            placeholder="Add 1-10 keywords that help users find your company. For example, B2B, SaaS, marketplace, design..."
            v-model="presentationForm.organization">
        </el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum12') }}
        </div>
        <el-input placeholder="Тема статьи" v-model="presentationForm.theme"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum13') }}
        </div>
        <el-upload
            class="upload-demo"
            drag
            action=""
            :auto-upload="false"
            :file-list="fileList"
            :limit="1"
            accept=".docx,.pdf,.pptx"
            :on-change="handleFileChange">
          <div class="el-upload__text">Click to upload or drag and drop</div>
          <div class="el-upload__text">SVG, PNG, JPG or GIF (max. 800x400px)</div>
        </el-upload>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum14') }}
        </div>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="Write a few sentences about the company..."
            v-model="presentationForm.section">
        </el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum15') }}
        </div>
        <el-input placeholder="Odratacer@gmail.com" v-model="presentationForm.email"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum16') }}
        </div>
        <el-input
            placeholder="+7 (777) 215-08-05"
            v-model="phone"
            v-mask="'+7 (###) ### ##-##'"
            @input="enforcePhoneFormat"
        ></el-input>
      </div>

      <div class="forums-btns">
        <button class="forums-btn" @click="modalSignupForumReport=false">
          {{ $t('profile.notes.cancel-btn-2') }}
        </button>
        <button class="forums-btn choose-btn" @click="successReg" :disabled="disabledBtn">
          {{ $t('profile.notes.btn-select') }}
        </button>
      </div>

    </el-dialog>

    <!-- Форма записи на форум (Участие без доклада и публикации) -->
    <el-dialog
        :visible.sync="modalSignupForumNoReport"
        :width="modalSignupForumWidth"
    >
      <div class="forum-signup-header">
        <img width="48" height="48" :src="forumInfo.banner" alt=""/>
        <div class="forum-signup-desc">
          <div class="forum-signup-title">
            {{ $t('modalRegForumTitle') }}
          </div>
          <div class="forum-signup-text">
            {{forumInfo.name}}
          </div>
        </div>
      </div>
      <div class="forum-price-title" v-if="!this.forumInfo.is_paid">
        {{ $t('modalRegForum1') }}
      </div>
      <div class="forum-role-block">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.8439 7.875C18.7065 9.78141 17.2923 11.25 15.7501 11.25C14.2079 11.25 12.7914 9.78188 12.6564 7.875C12.5157 5.89172 13.8925 4.5 15.7501 4.5C17.6078 4.5 18.9845 5.92781 18.8439 7.875Z" stroke="#242424" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.7498 14.25C12.695 14.25 9.75731 15.7673 9.02138 18.7223C8.92388 19.1133 9.16903 19.5 9.57075 19.5H21.9293C22.3311 19.5 22.5748 19.1133 22.4787 18.7223C21.7428 15.72 18.8051 14.25 15.7498 14.25Z" stroke="#242424" stroke-miterlimit="10"/>
          <path d="M9.37495 8.71594C9.26527 10.2384 8.12245 11.4375 6.89058 11.4375C5.65871 11.4375 4.51402 10.2389 4.40621 8.71594C4.29418 7.13203 5.40652 6 6.89058 6C8.37464 6 9.48698 7.16109 9.37495 8.71594Z" stroke="#242424" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9.65671 14.3439C8.81061 13.9563 7.87874 13.8072 6.89108 13.8072C4.45358 13.8072 2.10515 15.0189 1.51686 17.3791C1.43952 17.6913 1.63546 18.0002 1.95608 18.0002H7.21921" stroke="#242424" stroke-miterlimit="10" stroke-linecap="round"/>
        </svg>
        <div class="forum-role-desc">
          <div class="forum-role-title">
            {{ $t('modalRegForum5') }}
          </div>
          <div class="forum-role-desc">
            {{ $t('modalRegForum6') }}
          </div>
        </div>
      </div>
      <div class="forum-role">
        {{ $t('profile.consultations.modal-title') }}
      </div>
      <div class="forum-plan">
<!--        на участие во II Международном форуме психологов-->
<!--        (без доклада, без публикации, просто участие)-->
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum17') }}
        </div>
        <el-input placeholder="Одра Такер " v-model="presentationForm.name"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum10') }}
        </div>
        <el-input placeholder="Доктор психологических наук" v-model="presentationForm.position"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum11') }}
        </div>
        <el-input
            type="textarea"
            :rows="4"
            placeholder="Add 1-10 keywords that help users find your company. For example, B2B, SaaS, marketplace, design..."
            v-model="presentationForm.organization">
        </el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum18') }}
        </div>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="Add 1-10 keywords that help users find your company. For example, B2B, SaaS, marketplace, design..."
            v-model="presentationForm.section">
        </el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum15') }}
        </div>
        <el-input placeholder="Odratacer@gmail.com" v-model="presentationForm.email"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum16') }}
        </div>
        <el-input
            placeholder="+7 (777) 215-08-05"
            v-model="phone"
            v-mask="'+7 (###) ### ##-##'"
            @input="enforcePhoneFormat"
        ></el-input>
      </div>

      <div class="forums-btns">
        <button class="forums-btn" @click="modalSignupForumNoReport=false">
          {{ $t('profile.notes.cancel-btn-2') }}
        </button>
        <button class="forums-btn choose-btn" @click="successReg" :disabled="disabledBtn">
          {{ $t('profile.notes.btn-select') }}
        </button>
      </div>

    </el-dialog>

    <!-- Форма записи на форум (Только публикация) -->
    <el-dialog
        :visible.sync="modalSignupForumPublication"
        :width="modalSignupForumWidth"
    >
      <div class="forum-signup-header">
        <img width="48" height="48" :src="forumInfo.banner" alt=""/>
        <div class="forum-signup-desc">
          <div class="forum-signup-title">
            {{ $t('modalRegForumTitle') }}
          </div>
          <div class="forum-signup-text">
            {{forumInfo.name}}
          </div>
        </div>
      </div>
      <div class="forum-price-title" v-if="!this.forumInfo.is_paid">
        {{ $t('modalRegForum1') }}
      </div>
      <div class="forum-role-block">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.81203 17.0833H17.9862C19.7279 17.0833 20.6445 16.1667 20.6445 14.425V3.33331H4.14453V14.425C4.1537 16.1667 5.07037 17.0833 6.81203 17.0833Z" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3.23828 3.33331H21.5716" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.73828 21.6666L12.4049 19.8333V17.0833" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.071 21.6666L12.4043 19.8333" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.2793 11.5833L11.1668 9.17248C11.396 8.97998 11.6985 9.03498 11.8543 9.29165L12.9543 11.125C13.1101 11.3816 13.4126 11.4275 13.6418 11.2442L16.5293 8.83331" stroke="#292D32" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="forum-role-desc">
          <div class="forum-role-title">
            {{ $t('modalRegForum7') }}
          </div>
          <div class="forum-role-desc">
            {{ $t('modalRegForum8') }}
          </div>
        </div>
      </div>
      <div class="forum-role">
        {{ $t('profile.consultations.modal-title') }}
      </div>
      <div class="forum-plan">
<!--        на участие во II Международном форуме психологов-->
<!--        (выступление с докладом и публикация)-->
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum9') }}
        </div>
        <el-input placeholder="Одра Такер " v-model="presentationForm.name"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum10') }}
        </div>
        <el-input placeholder="Доктор психологических наук" v-model="presentationForm.position"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum11') }}
        </div>
        <el-input
            type="textarea"
            :rows="4"
            placeholder="Add 1-10 keywords that help users find your company. For example, B2B, SaaS, marketplace, design..."
            v-model="presentationForm.organization">
        </el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum12') }}
        </div>
        <el-input placeholder="Тема статьи" v-model="presentationForm.theme"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum13') }}
        </div>
        <el-upload
            class="upload-demo"
            drag
            action=""
            :auto-upload="false"
            :file-list="fileList"
            :limit="1"
            :on-change="handleFileChange">
          <div class="el-upload__text">Click to upload or drag and drop</div>
          <div class="el-upload__text">SVG, PNG, JPG or GIF (max. 800x400px)</div>
        </el-upload>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum14') }}
        </div>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="Write a few sentences about the company..."
            v-model="presentationForm.section">
        </el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          {{ $t('modalRegForum15') }}
        </div>
        <el-input placeholder="Odratacer@gmail.com" v-model="presentationForm.email"></el-input>
      </div>

      <div class="forum-modal-row">
        <div  class="forum-modal-label">
          successfullyRegistered
        </div>
        <el-input
            placeholder="+7 (777) 215-08-05"
            v-model="phone"
            v-mask="'+7 (###) ### ##-##'"
            @input="enforcePhoneFormat"
        ></el-input>
      </div>

      <div class="forums-btns">
        <button class="forums-btn" @click="modalSignupForumPublication=false">
          {{ $t('profile.notes.cancel-btn-2') }}
        </button>
        <button class="forums-btn choose-btn" @click="successReg" :disabled="disabledBtn">
          {{ $t('profile.notes.btn-select') }}
        </button>
      </div>

    </el-dialog>

    <el-dialog
        :visible.sync="modalSuccessfullyRegistered"
        :width="modalSuccessfullyRegisteredWidth"
    >
      <div class="modal-success-block">
        <img :src="forumInfo.banner" width="100%" alt="">
        <div class="modal-success-title">
          {{ $t('successfullyRegistered') }}
        </div>
        <div class="modal-success-text">
          {{forumInfo.name}}
        </div>
        <div class="modal-success-questions">
          {{ $t('successfullyRegistered1') }}
        </div>
        <div class="d-flex justify-content-between">
          <a href="mailto:qabilet@el-umiti.kz" class="modal-success-email">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.7725 16.2839H6.08694C3.78126 16.2839 2.24414 15.131 2.24414 12.4411V7.06118C2.24414 4.37122 3.78126 3.21838 6.08694 3.21838H13.7725C16.0782 3.21838 17.6153 4.37122 17.6153 7.06118V12.4411C17.6153 15.131 16.0782 16.2839 13.7725 16.2839Z" stroke="#1090CB" stroke-width="1.53712" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M13.7735 7.44543L11.3679 9.36683C10.5763 9.99705 9.27741 9.99705 8.48579 9.36683L6.08789 7.44543" stroke="#1090CB" stroke-width="1.53712" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            qabilet@el-umiti.kz
          </a>
          <div class="modal-success-phone">
            <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.84721 17.821C14.3041 17.821 17.9171 14.208 17.9171 9.75114C17.9171 5.29428 14.3041 1.68127 9.84721 1.68127C5.39035 1.68127 1.77734 5.29428 1.77734 9.75114C1.77734 11.1985 2.15836 12.5568 2.82555 13.7313L1.77734 17.821L5.99377 16.8433C7.13884 17.4668 8.45165 17.821 9.84721 17.821ZM9.84721 16.5795C13.6184 16.5795 16.6756 13.5223 16.6756 9.75114C16.6756 5.97995 13.6184 2.92279 9.84721 2.92279C6.07602 2.92279 3.01886 5.97995 3.01886 9.75114C3.01886 11.2072 3.47461 12.5568 4.25124 13.6652L3.63962 15.9587L5.97364 15.3752C7.07427 16.1347 8.4088 16.5795 9.84721 16.5795Z" fill="#1090CB" stroke="#1090CB" stroke-width="0.153712"/>
              <path d="M7.83107 6.00438C7.6392 5.619 7.34487 5.65312 7.04752 5.65312C6.51611 5.65312 5.6875 6.28964 5.6875 7.47429C5.6875 8.44516 6.11532 9.50794 7.55692 11.0978C8.94818 12.632 10.7762 13.4257 12.2938 13.3987C13.8114 13.3717 14.1236 12.0657 14.1237 11.6247C14.1237 11.4292 14.0023 11.3317 13.9188 11.3052C13.4016 11.057 12.4478 10.5946 12.2308 10.5077C12.0137 10.4208 11.9004 10.5383 11.83 10.6022C11.6332 10.7898 11.243 11.3425 11.1094 11.4669C10.9759 11.5912 10.7767 11.5283 10.6938 11.4813C10.3889 11.3589 9.562 10.9911 8.90289 10.3522C8.08775 9.56198 8.03991 9.29011 7.88634 9.04812C7.76348 8.85452 7.85363 8.73574 7.89862 8.68383C8.07425 8.48118 8.31675 8.16832 8.4255 8.01284C8.53426 7.85736 8.44792 7.62131 8.39612 7.47429C8.17332 6.84199 7.98457 6.31268 7.83107 6.00438Z" fill="#1090CB"/>
            </svg>
            8 727 310 02 58
          </div>
        </div>
        <a href="https://t.me/qabiletevent" class="modal-success-telegram">
          <svg width="20" height="19" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
            <path fill="#1090CB"
                  d="m231.25586 31.73635a15.96358 15.96358 0 0 0 -16.29-2.76758l-184.55717 72.50488a15.99988 15.99988 0 0 0 2.7124 30.58106l46.87891 9.37598v58.55371a15.99415 15.99415 0 0 0 27.31348 11.31347l25.94336-25.94287 39.376 34.65088a15.86863 15.86863 0 0 0 10.51709 4.00293 16.15443 16.15443 0 0 0 4.96338-.78711 15.86491 15.86491 0 0 0 10.68457-11.65332l37.61374-164.13281a15.96073 15.96073 0 0 0 -5.15576-15.69922zm-145.10986 94.60644-49.88526-9.977 139.68164-54.87529zm9.854 73.63379v-47.40771l25.21973 22.1936zm87.20215 8.01758-82.39209-72.50586 118.645-85.687z"/>
          </svg>
          t.me/qabiletevent
        </a>
        <router-link to="/events" class="modal-success-btn">{{ $t('successfullyRegistered2') }}</router-link>
      </div>


    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask'
import md5 from "@/plugins/md5";

export default {
  beforeCreate() {
    Vue.use(VueMask);
  },
  data() {
    return {
      daysProgram: [],
      disabledBtn: false,
      phone: '',
      perPageSpeakers: 3,
      perPageDays: 2.5,
      forumInfo: {},
      modalCurrentRole: 'withReport',
      modalSignupForum: false,
      modalSignupForumReport: false,
      modalSignupForumNoReport: false,
      modalSignupForumPublication: false,
      modalSuccessfullyRegistered: false,
      modalSignupForumWidth: "688px",
      modalSuccessfullyRegisteredWidth: "400px",
      block1Top: 0,
      block2Top: 0,
      block3Top: 0,
      blocksHeight: 0,
      fileList: [],
      formType: {
        withReport : 'with_speech_and_publication',
        noReport : 'without_speech_and_publication',
        onlyPublication : 'only_publication',
      },
      presentationForm: {
        name : '',
        position: '',
        organization: '',
        theme : '',
        section: '',
        email: '',
        phone: '',
        payment_status: false
      },
      lastInvoice: 1
    }
  },
  methods : {
    formatTime(time) {
      if (!time) return '';
      const [hours, minutes] = time.split(':');
      return `${hours}:${minutes}`;
    },
    validateForm(formData) {
      const { name, position, organization, theme, section, email, phone } = formData;

      if (!name || !position || !organization || !section) {
        Vue.toastr({
          message: 'Ошибка',
          description: "Все поля должны быть заполнены",
          type: 'error'
        })
        return false;
      }

      if (this.modalCurrentRole != 'noReport' && !theme) {
        Vue.toastr({
          message: 'Ошибка',
          description: "Все поля должны быть заполнены",
          type: 'error'
        })
        return false;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email || !emailPattern.test(email)) {
        Vue.toastr({
          message: 'Ошибка',
          description: "Поле email должно быть корректным email адресом",
          type: 'error'
        })
        return false;
      }

      if (!phone || phone.length !== 11) {
        Vue.toastr({
          message: 'Ошибка',
          description: "Напишите номер телефона полностью",
          type: 'error'
        })
        return false;
      }

      if (this.modalCurrentRole != 'noReport' && this.fileList.length === 0) {
        Vue.toastr({
          message: 'Ошибка',
          description: "Загрузите файл перед отправкой",
          type: 'error'
        })
        return false;
      }

      return true;
    },
    enforcePhoneFormat() {
      let x = this.phone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.presentationForm.phone = x[0];
    },
    handleFileChange(file, fileList) {
      const fileSizeInMB = file.size / 1024 / 1024;
      const isLt50MB = fileSizeInMB < 50;

      if (!isLt50MB) {
        Vue.toastr({
          message: 'Ошибка',
          description: "Размер файла не должен превышать 50 МБ.",
          type: 'error'
        })
        this.fileList = fileList.filter(f => f !== file);
        return;
      }

      this.fileList = fileList;
    },
    formattedDate(date_from, date_to) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const dateFrom = new Date(date_from).toLocaleDateString('ru-RU', options);
      const dateTo = new Date(date_to).toLocaleDateString('ru-RU', options);
      return `${dateFrom} - ${dateTo} гг`;
    },
    successReg() {
      const formData = new FormData();

      if (!this.validateForm(this.presentationForm)) return

      if (this.modalCurrentRole == 'withReport') {
        formData.append('type', this.formType['withReport']);
        formData.append('theme', this.presentationForm.theme);

        if (this.fileList.length > 0) {
          formData.append('file', this.fileList[0].raw);
        }
      } else if (this.modalCurrentRole == 'noReport') {
        formData.append('type', this.formType['noReport']);
        formData.append('theme', 'no theme');
        formData.append('file', 'no file');
      } else {
        formData.append('type', this.formType['onlyPublication']);
        formData.append('theme', this.presentationForm.theme);
        if (this.fileList.length > 0) {
          formData.append('file', this.fileList[0].raw);
        }
      }

      formData.append('name', this.presentationForm.name);
      formData.append('payment_status', false);
      formData.append('phone', this.presentationForm.phone);
      formData.append('email', this.presentationForm.email);
      formData.append('section', this.presentationForm.section);
      formData.append('position', this.presentationForm.position);
      formData.append('organization', this.presentationForm.organization);
      formData.append('invoice', this.lastInvoice);
      formData.append('forum_id', this.$route.params.id);

      this.disabledBtn = true

      this.$http.post(window.API_ROOT + '/api/forum-request', formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        if (this.forumInfo.is_paid) { // Если форум бесплатный отключаем переход
          this.purchase(response.data.id);
        }
        this.presentationForm = {
          name: '',
          position: '',
          organization: '',
          theme: '',
          section: '',
          email: '',
          phone: '',
          payment_status: false
        }
        this.fileList = [];
        this.phone = ''
        this.modalSignupForumReport = false
        this.modalSignupForumNoReport = false
        this.modalSignupForumPublication = false
        if (!this.forumInfo.is_paid) { // Если форум бесплатный сразу показываем модалку
          this.modalSuccessfullyRegistered = true;
        }
      }).catch(error => {
          console.error('Ошибка', error);
      }).finally( () => {
        this.disabledBtn = false
      })
    },
    purchase(requestId) {
      const login = 'forqabilet';
      const password = 'nF0V8PXPze4Oe4hnHn7b';
      const invId = this.lastInvoice;

      let outSum = 0;
      if (this.modalCurrentRole == 'withReport') {
        outSum = parseInt(this.forumInfo.price_with_speech_and_publication).toString();
      } else if (this.modalCurrentRole == 'noReport') {
        outSum = parseInt(this.forumInfo.price_without_speech_and_publication).toString();
      } else {
        outSum = parseInt(this.forumInfo.price_only_publication).toString();
      }

      const signature = md5(`${login}:${outSum}:${invId}:${password}`);

      localStorage.setItem('forumData', JSON.stringify({
        requestId: requestId,
        forumId: this.$route.params.id,
      }));

      window.open(`https://auth.robokassa.kz/Merchant/Index.aspx?MerchantLogin=${login}&OutSum=${outSum}&InvoiceID=${invId}&SignatureValue=${signature}`);

      this.$http.get(`${window.API_ROOT}/api/forum-request/last-invoice`).then(({ data }) => {
        this.lastInvoice = data.invoice;
      });
    },
    nextModal() {
      if (this.modalCurrentRole == 'withReport') {
        this.modalSignupForumReport = true
      } else if (this.modalCurrentRole == 'noReport') {
        this.modalSignupForumNoReport = true
      } else {
        this.modalSignupForumPublication = true
      }

      this.modalSignupForum = false
    },
    handlePreview(file) {
      console.log('Preview:', file);
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      console.log('Remove:', file, fileList);
    },
    blockHeights() {
      const block1Height = this.$refs.block1.clientHeight;
      const block2Height = this.$refs.block2.clientHeight;
      const block3Height = this.$refs.block3.clientHeight;

      this.block1Top = 0;
      this.block2Top = -block1Height;
      this.block3Top = -block1Height - block2Height;
      this.blocksHeight = Math.max(block1Height, block2Height, block3Height)
    }
  },
  computed: {
    innerWidth() {
      return window.innerWidth > 1000
    },
    isBeforeForumDate() {
      const forumDate = new Date(this.forumInfo.date_from);
      console.log('this.forumInfo.dateTo', this.forumInfo.date_from)
      const currentDate = new Date();
      return currentDate < forumDate;
    },
  },
  mounted() {
    setTimeout(() => this.blockHeights(), 500);

    const id = this.$route.params.id
    this.$http.get(window.API_ROOT + `/api/events/forum/${id}`)
        .then(res => {
          this.forumInfo = res.body

          this.daysProgram = this.forumInfo.forumPrograms.reduce((acc, program) => {
            let group = acc.find(item => item.day_ru === program.day_ru);
            if (group) {
              group.data.push(program);
            } else {
              acc.push({
                day_ru: program.day_ru,
                day_kz: program.day_kz,
                day_en: program.day_en,
                data: [program],
              });
            }
            return acc;
          }, []);

          console.log('this.daysProgram', this.daysProgram)

          // if (this.forumInfo.forumPrograms.length > 1) {
          //   this.perPageDays = 2
          // } else {
          //   this.perPageDays = 1
          // }

          if (window.innerWidth < 1000) {
            this.perPageSpeakers = 1
            this.perPageDays = 1
          }
        });

    this.$http.get(`${window.API_ROOT}/api/forum-request/last-invoice`).then(({ data }) => {
      this.lastInvoice = data.invoice;
    });

    const showForumSuccess = localStorage.getItem('showForumSuccess');
    if (showForumSuccess && showForumSuccess === 'true') { // Если форум платный показываем модалку после перехода с оплаты
      this.modalSuccessfullyRegistered = true;
      localStorage.removeItem('showForumSuccess');
    }
  },
}
</script>

<style>
.one-forum-title-block {
  padding-top: 75px;
  background-color: #FFF;
  height: 600px;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
  position: relative;
}

.one-forum-title-block::before {
  content: "";
  position: absolute;
  top: -90px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;

  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(50, 67, 109, 0.46), rgba(50, 67, 109, 0.46)), url('/images/forum-section-img.png');
  background-size: 100% 150%;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 20%;
}

.one-forum-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  color: #FFF;
}

.one-forum-subtitle {
  font-size: 30px;
  font-weight: 800;
  line-height: 45px;
  color: #FFF;
  display: flex;
  gap: 25px;
  align-items: center;
}

.one-forum-details-block {
  display: flex;
  justify-content: space-between;
}

.one-forum-details-left {
  width: 49%;
}

.one-forum-details-right {
  width: 49%;
  display: flex;
  flex-direction: column;
}

.one-forum-results {
  box-shadow: 0px 2.74px 105.6px 0px #0000000A;
  padding: 40px 0 40px 40px;
  position: relative;
}

.one-forum-results::before {
  position: absolute;
  content: "";
  width: 7px;
  height: 138px;
  background-color: #0CACDE;
  top: 50px;
  left: 40px;
}

.one-forum-results::after {
  position: absolute;
  content: "";
  width: 100vw;
  height: 100%;
  background-color: #FFF;
  top: 0px;
  left: 538px;
}

.one-forum-section-title {
  font-size: 36px;
  font-weight: 600;
  line-height: 61.2px;
  color: #32436D;
  margin-bottom: 85px;
}

.one-forum-section-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  color: #545454;
  margin-bottom: 25px;
  white-space: pre-line;
}

.one-forum-details-block .line-top {
  margin-top: 40px;
  margin-bottom: 25px;
}

.one-forum-results-title {
  padding-left: 32px;
  font-size: 36px;
  font-weight: 600;
  line-height: 61.2px;
  color: #32436D;
}

.one-forum-results-text {
  padding-left: 32px;
  font-size: 18px;
  font-weight: 400;
  line-height: 30.6px;
  color: #545454;
  margin-bottom: 80px;
  white-space: pre-line;
}

.one-forum-results-details {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #2E0D64;

  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.one-forum-speakers-block .line-top {
  margin-bottom: 21px;
}

.one-forum-speakers-slider {

}

.one-forum-speakers-carousel {

}

.one-forum-slide-details {
  display: flex;
  gap: 25px;
  align-items: center;
}

.one-forum-slide-details img {
  border-radius: 50%;
}

.one-forum-slide-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #32436D;
}

.one-forum-slide-job {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #9497A1;
}

.one-forum-slide-text {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #9497A1;
}

.one-forum-slide-wrapper {

}

/*
.one-forum-slide {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  border-radius: 20px;
  max-height: 720px;
  overflow-y: auto;
}

 */

.one-forum-slide {
  padding: 35px;
  box-shadow: 0px 4px 12px 0px rgba(12, 68, 204, 0.1);
  display: flex;
  flex-direction: column;
  gap: 25px;
}



.one-forum-slide-block {
  padding: 4px;
  padding-right: 25px;
}

.one-forum-speakers-slider .VueCarousel-navigation-prev {
  top: -50px;
  left: 88% !important;
  background: #F0F9FF;
  width: 110px;
  height: 60px;
  color: #32436D !important;
  border-radius: 20px;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center center;
  background-image: url("/images/arrow-desktop-left.svg");
}

.one-forum-speakers-slider .VueCarousel-navigation-next {
  top: -50px;
  left: 80% !important;
  background: #F0F9FF;
  width: 110px;
  height: 60px;
  font-size: 0;
  color: #32436D !important;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center center;
  background-image: url("/images/arrow-desktop-right.svg");
}

.one-forum-program-block {
  margin-bottom: 100px;
}

.one-forum-program-block .line-top {
  margin-top: 30px;
  margin-bottom: 25px;
}

.one-forum-details-block .one-forum-section-title {
  margin-bottom: 25px;
}

.one-forum-program-block .one-forum-section-title {
  margin-bottom: 25px;
}

.one-forum-program-link {
  border: 1.33px dashed #1090CB;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: #1090CB;
  padding: 16px 51px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 15px;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.one-forum-program-link:hover {
  text-decoration: none;
  transform: scale(1.05);
  color: #1090CB;
}

.one-forum-program-day {
  max-width: 700px;
  padding: 33px 15px;
  box-shadow: 0px 2.74px 105.6px 0px #0000000A;

}

.day-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #1090CB;
  margin-bottom: 40px;
}

.day-section-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #545454;
  margin-bottom: 25px;
}

.accent-color {
  color: #1090CB;
}

.one-forum-program-days {
    margin-top: 100px;
}

@media screen and (max-width: 991px) {

  .one-forum-speakers-slider .VueCarousel-navigation-prev {
    color: #32436D !important;
    font-size: 0;
    background: #C9DCEC;
    width: 48px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: center center;
    background-image: url("/images/arrow-mobile-left.svg");
    position: absolute;
    left: 50px !important;
    top: -33px;
    cursor: pointer;
    border-radius: 14px;
  }

  .one-forum-speakers-slider .VueCarousel-navigation-next {
    color: #32436D !important;
    font-size: 0;
    background: #C9DCEC !important;
    width: 48px;
    height: 40px;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    background-position: center center !important;
    background-image: url("/images/arrow-mobile-right.svg") !important;
    position: absolute !important;
    left: 20px !important;
    top: -33px !important;
    cursor: pointer;
    border-radius: 14px;
  }

}

@media screen and (max-width: 767px) {


  .one-forum-program-days .VueCarousel-inner {
    width: 100%;
  }

  .one-forum-results::before {
    display: none;
  }

  .one-forum-results {
    padding: 0;
    box-shadow: none;
  }

  .one-forum-results-title, .one-forum-results-text {
    padding-left: 0;
  }

  .one-forum-results-text {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 567px) {



}

@media screen and (max-width: 487px) {



}

@media screen and (max-width: 420px) {

  .one-forum-program-link, .one-forum-signup {
    display: inline-flex;
    gap: 20px;
    width: 100%;
    justify-content: center;
  }

}

</style>